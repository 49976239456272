import React from 'react';
import Layout from '../../components/layout/Layout';
import dossier from '../../data/pratiche-patenti/smarrimento-deterioramento-rinnovo/dossier.json';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentDossierPrice from '../../components/ContentDossierPrice';
import Seo from '../../components/layout/SEO';

const SmarrimentoDeterioramentoRinnovo = () => {
  return (
    <Layout>
      <Seo
        title={"Rinnovo Patente dopo Smarrimento o Deterioramento"}
        description={"Da Autoscuola Faro a Pisa puoi rinnovare la tua patente a seguito di deterioramento, o smarrimento nei 4 mesi prima della scadenza."}
        keywords={[
          "rinnovo patente Pisa dopo smarrimento",
          "rinnovo patente Pisa per deterioramento",
          "rinnovare patente smarrita Pisa"
        ]}
      />

      <PageNavigator link="/pratiche-patenti" text="Pratiche patenti" />
      <ContentDossierPrice dossier={dossier} />
    </Layout>
  );
};

export default SmarrimentoDeterioramentoRinnovo;
